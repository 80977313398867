<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-postal-address', params: { accountId: this.$route.params.accountId } }">Postal Addresses</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ postalAddress.mailbox }}@{{ postalAddress.domain }}</h1>
                <p class="text-caption text-center">Postal address overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2 mx-5" v-if="postalAddress">
                <v-col cols="12">
                    <v-card>
                        <v-toolbar color="teal darken-2" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <!-- <v-toolbar-title>{{ postalAddress.label }}</v-toolbar-title> -->
                        <v-toolbar-title>Postal Address</v-toolbar-title>

                        <!-- <v-spacer/>
                        <v-btn icon color="white" @click="editPostalAddress">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->

                        </v-toolbar>

                        <v-card-text>
                            <p class="font-weight-bold mb-0 pb-0">
                                {{postalAddress.label}}
                                <v-btn icon color="teal darken-2" @click="editPostalAddress">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>
                            <p class="mb-0 pb-0" v-if="postalAddress.line1">{{postalAddress.line1}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.line2">{{postalAddress.line2}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.line3">{{postalAddress.line3}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.line4">{{postalAddress.line4}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.line5">{{postalAddress.line5}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.city">{{postalAddress.city}}</p>
                            <!-- TODO: the order of code and state maybe should depend on the choice of country... also selections for cities and states depends on country... so maybe prompt for country first right after the label? -->
                            <p class="mb-0 pb-0" v-if="postalAddress.state">{{postalAddress.state}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.code">{{postalAddress.code}}</p>
                            <p class="mb-0 pb-0" v-if="postalAddress.country">{{postalAddress.country}}</p>

                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="postalAddress">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <p class="text-overline mb-0 mt-8">Postal Address ID</p>
                    <p class="mb-0 pb-0">
                        {{ postalAddress.id }}
                    </p>

                    <!-- TODO: postal address verification via postcard -->

                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailTemplateList.length > 0">
                            This postal address is referenced by {{ affectedEmailTemplateList.length }} templates.
                        </span>
                        <span v-if="affectedEmailTemplateList.length === 0">
                        This postal address is not currently being used by any email templates.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, postalAddressId: this.$route.params.postalAddressId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Postal Address Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.postalAddress, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editPostalAddressDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit postal address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            An address where your business can receive postal mail.
                        </p>

                        <!-- TODO: it would be nice to have just a textarea where user can type in their address normally, and we parse it into lines and try to detect the city, state, code, and country;  maybe have the two alternative views available with a switch so they can toggle betweeen editing whole address and line-by-line like what is already below; either way when we send to the server it needs to be line1, line2, etc. -->

                        <!-- TODO: on this line-by-line input, we should only show the first one or two line inputs, and a "+" button to reveal each subsequent line for line3, line4, line5 so it's not so cluttered if user isn't going to need those extra lines -->

                            <v-text-field
                                ref="postalAddressLabelInput"
                                v-model="editablePostalAddressLabel"
                                label="Label"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressLine1Input"
                                v-model="editablePostalAddressLine1"
                                label="Line 1"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressLine2Input"
                                v-model="editablePostalAddressLine2"
                                label="Line 2"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressLine3Input"
                                v-model="editablePostalAddressLine3"
                                label="Line 3"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressLine4Input"
                                v-model="editablePostalAddressLine4"
                                label="Line 4"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressLine5Input"
                                v-model="editablePostalAddressLine5"
                                label="Line 5"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressCityInput"
                                v-model="editablePostalAddressCity"
                                label="City"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <!-- TODO: if we ask for country at the top instead of at the bottom, then based on the country we can show a state selection combo box here with state abbreviation (AZ, TX,etc.) and full name -->
                            <v-text-field
                                ref="postalAddressStateInput"
                                v-model="editablePostalAddressState"
                                label="State"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                ref="postalAddressCodeInput"
                                v-model="editablePostalAddressCode"
                                label="Postal code"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <!-- TODO: need country selection combo box where user can type in  a partial name or scroll to it ; and it would be nice to show the flag icon next to each one -->
                            <v-text-field
                                ref="postalAddressCountryInput"
                                v-model="editablePostalAddressCountry"
                                label="Country"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditPostalAddress" :disabled="!isEditPostalAddressComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editPostalAddressDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import PostalAddressList from '@/components/account-dashboard/PostalAddressList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // PostalAddressList,
    },
    data: () => ({
        // affectedEmailTemplateList: [],

        error: null,
        account: null,
        postalAddress: null,
        // edit form title dialog
        editPostalAddressDialog: false,
        editablePostalAddressLabel: null,
        editablePostalAddressLine1: null,
        editablePostalAddressLine2: null,
        editablePostalAddressLine3: null,
        editablePostalAddressLine4: null,
        editablePostalAddressLine5: null,
        editablePostalAddressCity: null,
        editablePostalAddressState: null,
        editablePostalAddressCode: null,
        editablePostalAddressCountry: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.postalAddress !== null;
        },
        isEditPostalAddressComplete() {
            return typeof this.editablePostalAddressLabel === 'string' && this.editablePostalAddressLabel.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadPostalAddress() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPostalAddress: true });
                const response = await this.$client.account(this.$route.params.accountId).postalAddress.get(this.$route.params.postalAddressId);
                console.log(`loadPostalAddress: response ${JSON.stringify(response)}`);
                if (response) {
                    this.postalAddress = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.label ?? '';
                    this.postalAddress.label ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load postal address');
                }
            } catch (err) {
                console.error('failed to load postal address', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadPostalAddress: false });
            }
        },
        async save(itemAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditPostalAddress: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).postalAddress.edit({ id: this.$route.params.postalAddressId }, itemAttr);
                console.log(`saveEditPostalAddress: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit postal address' });
                return false;
            } catch (err) {
                console.error('failed to edit postal address', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit postal address' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditPostalAddress: false });
            }
        },
        editPostalAddress() {
            this.editPostalAddressDialog = true;
            this.editablePostalAddressLabel = this.postalAddress.label;
            this.editablePostalAddressLine1 = this.postalAddress.line1;
            this.editablePostalAddressLine2 = this.postalAddress.line2;
            this.editablePostalAddressLine3 = this.postalAddress.line3;
            this.editablePostalAddressLine4 = this.postalAddress.line4;
            this.editablePostalAddressLine5 = this.postalAddress.line5;
            this.editablePostalAddressCity = this.postalAddress.city;
            this.editablePostalAddressState = this.postalAddress.state;
            this.editablePostalAddressCode = this.postalAddress.code;
            this.editablePostalAddressCountry = this.postalAddress.country;
        },
        async saveEditPostalAddress() {
            const isEdited = await this.save({
                label: this.editablePostalAddressLabel,
                line1: this.editablePostalAddressLine1,
                line2: this.editablePostalAddressLine2,
                line3: this.editablePostalAddressLine3,
                line4: this.editablePostalAddressLine4,
                line5: this.editablePostalAddressLine5,
                city: this.editablePostalAddressCity,
                state: this.editablePostalAddressState,
                code: this.editablePostalAddressCode,
                country: this.editablePostalAddressCountry,
            });
            if (isEdited) {
                this.editPostalAddressDialog = false;
                this.$set(this.postalAddress, 'label', this.editablePostalAddressLabel);
                this.$set(this.postalAddress, 'line1', this.editablePostalAddressLine1);
                this.$set(this.postalAddress, 'line2', this.editablePostalAddressLine2);
                this.$set(this.postalAddress, 'line3', this.editablePostalAddressLine3);
                this.$set(this.postalAddress, 'line4', this.editablePostalAddressLine4);
                this.$set(this.postalAddress, 'line5', this.editablePostalAddressLine5);
                this.$set(this.postalAddress, 'city', this.editablePostalAddressCity);
                this.$set(this.postalAddress, 'state', this.editablePostalAddressState);
                this.$set(this.postalAddress, 'code', this.editablePostalAddressCode);
                this.$set(this.postalAddress, 'country', this.editablePostalAddressCountry);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadPostalAddress();
    },
};
</script>
